process.env.I18NEXT_DEFAULT_CONFIG_PATH = __filename;
/** @type {import('next-i18next').UserConfig} */
module.exports = {
  serializeConfig: false,
  debug: false, //process.env.NODE_ENV === "development",
  i18n: {
    defaultLocale: "ua",
    locales: ["ua"],
  },
  defaultNS: "header",
  reloadOnPrerender: process.env.NODE_ENV === "development",
};
